export const recordsColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    width: 200,
    dataIndex: 'fileName',
    key: 'fileName',
    slots: { customRender: 'fileName' }
    // fixed: 'left',
  },
  {
    title: '文件类型',
    dataIndex: 'fileTypeName',
    key: 'fileTypeName',
    slots: { customRender: 'fileTypeName' },
    width: 220
  },
  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    slots: { customRender: 'staffName' },
    width: 200
  },
  {
    title: '盖印人',
    dataIndex: 'affixedStaffname',
    key: 'affixedStaffname',
    slots: { customRender: 'affixedStaffname' },
    width: 200
  },
  {
    title: '归档人',
    dataIndex: 'archiveStaffname',
    key: 'archiveStaffname',
    slots: { customRender: 'archiveStaffname' },
    width: 200
  },
  {
    title: '印章名称',
    dataIndex: 'sealName',
    key: 'sealName',
    slots: { customRender: 'sealName' },
    width: 200
  },
  {
    title: '业务类型',
    dataIndex: 'processType',
    key: 'processType',
    slots: { customRender: 'processType' },
    width: 150
  },
  {
    title: '流程名称',
    dataIndex: 'processName',
    key: 'processName',
    slots: { customRender: 'processName' },
    width: 220
  },
  {
    title: '归档时间',
    dataIndex: 'archiveTime',
    key: 'archiveTime',
    slots: { customRender: 'archiveTime' },
    width: 200
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
    width: 76,
    align: 'left',
    fixed: 'right'
  }
]
